import * as React from "react";

import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "~/lib/utils";

const variants = {
  default: cn(
    "border-transparent bg-badge text-badge-foreground hover:bg-badge-hover hover:shadow-badge/60",
  ),
  error:
    "border-transparent bg-badge-error text-badge-error-foreground hover:bg-badge-error-hover hover:shadow-badge-error/60",
  success:
    "border-transparent bg-badge-success text-badge-success-foreground hover:bg-badge-success-hover hover:shadow-badge-success/60",
  warning:
    "border-transparent bg-badge-warning text-badge-warning-foreground hover:bg-badge-warning-hover hover:shadow-badge-warning/60",
};

const badgeVariants = cva(
  cn(
    "inline-flex items-center justify-center gap-1 rounded-full border px-2 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
    "hover:shadow hover:shadow-x-0 hover:-shadow-y-0.5 hover:shadow-blur-0.5",
  ),
  {
    variants: {
      variant: variants,
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export const BADGE_VARIANT_KEYS = Object.keys(variants) as Array<
  keyof typeof variants
>;
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
