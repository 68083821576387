import React from "react";

import { observer } from "mobx-react-lite";

import useLogSessionActivity from "../hooks/useLogSessionActivity";
import { useStore as useRootStore } from "../models/Root";

import { Button } from "./ui-rework/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui-rework/dialog";

const InactivityDialog: React.FC = observer(() => {
  const { logSessionExtension, logout } = useLogSessionActivity();
  
  const {
    getSessionExpirationDialog,
    setSessionExpirationDialog
  } = useRootStore();

  const extendSession = () => {
    setSessionExpirationDialog(false);
    logSessionExtension();
  };

  return (
    <div className="light">
      <Dialog
        open={getSessionExpirationDialog()}
      >
        <DialogContent hideCloseButton>
          <DialogHeader>
            <DialogTitle>Your Session Is Expiring</DialogTitle>
            <DialogDescription>
              Your session is about to expire due to inactivity.
              <br />
              Would you like to continue?
            </DialogDescription>
            <div className="flex flex-row justify-center items-center pt-4">
              <Button
                className="mr-4"
                variant="primary"
                onClick={() => {
                  extendSession();
                }}
              >
                Continue Session
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  logout();
                }}
              >
                Log Out
              </Button>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
});

export default InactivityDialog;
