import React from "react";
import { useForm } from "react-hook-form";


import { zodResolver } from "@hookform/resolvers/zod";
import { observer } from "mobx-react-lite";
import { z } from "zod";

import { ApiUrls } from "~/api/ApiPaths";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "~/components/ui-rework/card";
import useAxios from "~/hooks/useAxios";

import { Button } from "../ui-rework/button";
import { Input } from "../ui-rework/input";
import Link from "../ui-rework/link";

const ForgotPassword: React.FC<{
  setShowLogin: React.Dispatch<React.SetStateAction<boolean>>;
}> = observer(({ setShowLogin }) => {
  const [emailSent, setEmailSent] = React.useState(false);
  const { data, payload, requestUrl, fetch } = useAxios({
    method: "POST",
    initialValue: null,
  });

  const formSchema = z.object({
    email: z
      .string()
      .email("Invalid email address")
      .max(100, "Invalid email address")
      .min(1, { message: "Email is required" }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    sendEmail(values.email);
  }

  const sendEmail = (email: string) => {
    payload.current = {
      email: email,
    };
    requestUrl.current = `${ApiUrls.account.resetPasswordRequest}`;
    fetch();
  };

  React.useEffect(() => {
    if (data) {
      setEmailSent(true);
    }
  }, [data]);

  return (
    <div>
      {!emailSent ? (
        <Card className="w-[415px]">
          <CardHeader>
            <CardTitle>Forgot Your Password?</CardTitle>
            <CardDescription>
              Enter your email address to receive a reset link.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...form}>
              <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="w-[345px] mb-4">
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <FormControl>
                        <Input
                          className="bg-transparent"
                          placeholder="Enter Email"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="w-full flex flex-row justify-end gap-4">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => {
                      setShowLogin(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Send Link
                  </Button>
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>
      ) : (
        <div>
          <Card className="w-[415px]">
            <CardHeader>
              <CardTitle>Email Sent</CardTitle>
              <CardDescription>
                If your email is in our system, a reset link will be sent to
                your inbox.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="flex justify-end">
                <Button variant="primary" onClick={() => setShowLogin(true)}>
                  Return to Login
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      )}
      {/* <div className="flex flex-col justify-center items-center pt-6">
        {!emailSent && (
          <div className="flex justify-center items-center">
            <p className="font-bold mr-6 text-foreground">Don't have an account?</p>
            <Link to="" inline size="m">
              Learn More
            </Link>
          </div>
        )}
        <Link to="" size="m">
          Contact Support
        </Link>
      </div> */}
    </div>
  );
});

export default ForgotPassword;
