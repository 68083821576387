import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { API_PATHS } from "~/api/ApiPaths";
import useAxios from "~/hooks/useAxios";
import { useStore as useRootStore } from "~/models/Root";

const enum LOG_TYPES {
  PAGE_VIEW = "Page View",
  CONTENT_VIEW = "Content View",
  SESSION_EXTENSION = "Session Extension",
}

const useLogSessionActivity = () => {
  const navigate = useNavigate();
  const {
    getAppUser,
    setSessionExpirationDialog,
    setAuthToken,
    resetAppUser,
    setLoggedIn,
    getSessionExpirationTimer,
    getFinalSessionExpirationTimer,
    setSessionExpirationTimer,
    setFinalSessionExpirationTimer,
  } = useRootStore();
  const sessionTimer = getSessionExpirationTimer();
  const finalSessionTimer = getFinalSessionExpirationTimer();
  const appUser = getAppUser();
  const { authToken, requestUrl, fetch, payload } = useAxios({
    method: "POST",
    initialValue: null,
  });

  const logPageView = (relativePath: string) => {
    // console.log("Page View Logged");
    logActivity(LOG_TYPES.PAGE_VIEW, relativePath);
  };

  const logContentView = (relativePath: string, details: string) => {
    // console.log("Content View Logged");
    logActivity(LOG_TYPES.CONTENT_VIEW, relativePath, details);
  };

  const logSessionExtension = () => {
    // console.log("Session Extension Logged");
    logActivity(LOG_TYPES.SESSION_EXTENSION, "");
  };

  const logout = useCallback(() => {
    // console.log("Logging Out");
    //Logout Now and Redirect to Login
    requestUrl.current = API_PATHS.LOGOUT;
    authToken.current = appUser.authtoken;
    fetch();
    if (sessionTimer !== null) {
      // console.log("CLEAR First");
      clearTimeout(sessionTimer);
      setSessionExpirationTimer(null);
    }
    if (finalSessionTimer !== null) {
      // console.log("CLEAR Final");
      clearTimeout(finalSessionTimer);
      setFinalSessionExpirationTimer(null);
    }
    setSessionExpirationDialog(false);
    resetAppUser();
    setAuthToken("");
    setLoggedIn(false);
    navigate("/login", { replace: true });
  }, [requestUrl, authToken, appUser, sessionTimer, fetch, setSessionExpirationDialog, resetAppUser, setAuthToken, setLoggedIn, navigate, setSessionExpirationTimer, finalSessionTimer, setFinalSessionExpirationTimer]);

  const logActivity = (
    activityType: string,
    relativePath: string,
    details: string | null = null,
  ) => {
    setSessionTimer();
    requestUrl.current = `${API_PATHS.LOG_SESSION_ACTIVITY}`;
    authToken.current = appUser.authtoken;
    payload.current = {
      activityType: activityType,
      relativeUri: relativePath,
      details: details ? { content: details } : null,
    };
    fetch();
  };

  const setSessionTimer = () => {
    // console.log("Current sessionTimer:", sessionTimer);
    // console.log("Current finalSessionTimer:", finalSessionTimer);
    // console.log("Setting Session Timer");
    if (sessionTimer !== null) {
      // console.log("CLEAR First");
      clearTimeout(sessionTimer);
      setSessionExpirationTimer(null);
    }
    if (finalSessionTimer !== null) {
      // console.log("CLEAR Final");
      clearTimeout(finalSessionTimer);
      setFinalSessionExpirationTimer(null);
    }
    setSessionExpirationTimer(window.setTimeout(
      () => {
        // console.log("Frontend Timer Expired");
        setSessionExpirationDialog(true);
        setFinalTimeout();
      },
      60 * 60 * 1000,
    ));
  };

  const setFinalTimeout = () => {
    // console.log("Setting Final Timeout");
    setFinalSessionExpirationTimer(window.setTimeout( () => {
      // console.log("Final Timeout Expired");
      logout();
    }, 5 * 60 * 1000));
  }

  return { logPageView, logContentView, logSessionExtension, logout };
};

export default useLogSessionActivity;
