import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import "~/components/Reports/ReportStyles.css";

import { LaptopIcon, ServerIcon } from "lucide-react";
import { observer } from "mobx-react-lite";

import { API_PATHS } from "~/api/ApiPaths";
import AlignmentGrid from "~/components/Reports/AlignmentGrid";
import CourseActivities from "~/components/Reports/CourseActivities";
import CourseModules from "~/components/Reports/CourseModules";
import { ListToList } from "~/components/Reports/ListToList/ListToList";
import ProgramActivities from "~/components/Reports/ProgramActivities";
import { List, ProgramData } from "~/components/Reports/ReportModels";
import ToteSummary from "~/components/Reports/ToteSummary";
import { ScrollArea } from "~/components/ui/scroll-area";
import { SelectOption } from "~/components/ui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "~/components/ui-rework/tabs";
import useAxios from "~/hooks/useAxios";
import useLogSessionActivity from "~/hooks/useLogSessionActivity";
import useOverrides from "~/hooks/useOverrides";
import EmptyStateGraphic from "~/images/Reports-Page-Empty-State.svg";
import { useStore } from "~/models/Root";

const Reports: React.FC = observer(() => {
  const [programData, setProgramData] = React.useState<ProgramData | null>(
    null,
  );
  const [hasProgramData, setHasProgramData] = React.useState<boolean>(false);
  const { data, requestUrl, authToken, error, fetch } = useAxios({
    method: "GET",
    initialValue: null,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const { getLoggedIn, getAppUser, getAuthToken, setDropdownListReports } =
    useStore();
  const [appUser] = React.useState(getAppUser());
  const {
    ProgramLabel,
    PartnerLabel,
    CourseLabel,
    ModulesLabel,
    ActivitiesLabel,
  } = useOverrides();
  const programLabel = ProgramLabel();
  const partnerLabel = PartnerLabel();
  const courseLabel = CourseLabel();
  const activitiesLabel = ActivitiesLabel();
  const modulesLabel = ModulesLabel();
  const needToFetch = React.useRef<boolean>(true);
  const readyToFetch = React.useRef<boolean>(false);
  const displayReady = React.useRef<boolean>(false);
  const location = useLocation();
  const { logContentView } = useLogSessionActivity();

  const tabNames = useMemo(
    () => [
      "Time on Task Summary",
      "Alignment Grid",
      "List to List",
      `${programLabel} ${activitiesLabel}`, // Program Activities
      `${courseLabel} ${activitiesLabel}`, // Course Activities
      `${courseLabel} ${modulesLabel}`, // Course Modules
      // "Printable Report",
    ],
    [programLabel, activitiesLabel, courseLabel, modulesLabel],
  );

  //context is set and ready to fetch
  useEffect(() => {
    if (
      appUser.selected_program_id !== 0 &&
      appUser.selected_partner_id !== 0
    ) {
      readyToFetch.current = true;
    } else if (appUser.selected_program_id === 0) {
      readyToFetch.current = false;
    }
  }, [appUser.selected_program_id, appUser.selected_partner_id]);

  // triggered when context changes and need to reload report data
  useEffect(() => {
    if (appUser.selected_program_id !== 0 && !needToFetch.current) {
      needToFetch.current = true;
      displayReady.current = false;
      setProgramData(null);
    }
  }, [appUser.selected_program_id]);

  useEffect(() => {
    if (
      data?.entity.id === appUser.selected_program_id &&
      !programData &&
      !needToFetch.current
    ) {
      const programDetails: ProgramData = data.entity;
      setProgramData(programDetails);
      setHasProgramData(programDetails?.courses?.length > 0);
      setIsLoading(false);
      logContentView(location.pathname, tabNames[selectedTab]);

      const dropDownItems: SelectOption[] = [];
      programDetails?.mapped_lists.forEach((item: List) => {
        dropDownItems.push({
          value: `${item.id}`,
          label: item.name,
        });
      });
      setDropdownListReports(dropDownItems);
      displayReady.current = true;
    } else if (error) {
      setIsLoading(false);
      console.log(error);
    } else if (needToFetch.current && readyToFetch.current) {
      setIsLoading(true);
      needToFetch.current = false;
      requestUrl.current = `${API_PATHS.REPORTS_GET_PROGRAMDETAILS}/${appUser.selected_program_id}`; // This will have to come from the current context
      authToken.current = getAuthToken();
      fetch();
    }
  }, [
    programData,
    authToken,
    data,
    error,
    fetch,
    getAuthToken,
    requestUrl,
    getLoggedIn,
    appUser.selected_program_id,
    logContentView,
    tabNames,
    location.pathname,
    selectedTab,
    setDropdownListReports,
  ]);

  type CustomTabTriggerProps = {
    value: string;
  };

  const CustomTabTrigger = ({ value }: CustomTabTriggerProps) => (
    <TabsTrigger
      className="text-center"
      value={value}
      onClick={() => {
        setSelectedTab(tabNames.indexOf(value));
        logContentView(location.pathname, value);
      }}
    >
      {value}
    </TabsTrigger>
  );

  return (
    <div className="flex flex-row flex-1 w-full justify-start bg-background light overflow-hidden">
      <title>Reports - Align App</title>
      {appUser.selected_program_id === 0 ||
      appUser.selected_partner_id === 0 ? (
        <div className="h-full flex flex-row gap-9 px-24 pb-3 justify-center items-center">
          <img
            alt="List Page Graphic"
            src={EmptyStateGraphic}
            className="w-[632px] h-[632px] pointer-events-none select-none"
          />
          <div className="flex flex-col gap-4 justify-center max-w-fit">
            <h3 className="text-lg font-bold text-idesign-navy-100">
              {appUser.selected_partner_id === 0
                ? `Select a ${partnerLabel} and a ${programLabel}`
                : `Select a ${programLabel}`}
            </h3>
            <p className="text-base font-normal text-idesign-navy-100 w-[415px]">
              Select a{" "}
              {appUser.selected_partner_id === 0
                ? partnerLabel?.toLowerCase() + " and"
                : ""}{" "}
              {programLabel?.toLowerCase()} to view reports, including time on
              task, alignment, and details about courses, modules, and
              activities.
            </p>
          </div>
        </div>
      ) : isLoading ? (
        <div className="flex flex-col items-center gap-8 p-3 pt-0 w-full justify-center">
          <span className="text-lg font-bold">Fetching Reports...</span>

          <div className="flex justify-center items-center gap-6">
            <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-100">
              <ServerIcon size={38} className="stroke-white" />
            </div>
            <span
              style={{ animationDelay: "0ms" }}
              className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
            />
            <span
              style={{ animationDelay: "150ms" }}
              className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
            />
            <span
              style={{ animationDelay: "300ms" }}
              className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
            />
            <span
              style={{ animationDelay: "450ms" }}
              className="animate-blink h-4 w-4 rounded-full bg-ocean-100"
            />
            <div className="flex justify-center items-center rounded-full h-[72px] w-[72px] bg-ocean-30">
              <LaptopIcon size={38} className="stroke-white" />
            </div>
          </div>

          <p className="text-foreground center whitespace-pre-line">
            {"Studio is fetching reports data.\nResults are on the way!"}
          </p>
        </div>
      ) : hasProgramData && displayReady.current ? (
        <div className="w-full h-full light bg-background">
          <Tabs
            variant="secondary"
            defaultValue={tabNames[0]}
            className="overflow-hidden h-full grid gap-2"
            style={{ gridTemplateRows: "auto 1fr" }}
          >
            <div className="px-3 py-1">
              <TabsList className="flex flex-row w-full justify-center sticky top-0">
                {tabNames.map((tabName) => (
                  <CustomTabTrigger key={tabName} value={tabName} />
                ))}
              </TabsList>
            </div>
            <div className="overflow-hidden p-3 pt-0 h-full">
              <TabsContent
                className="h-full overflow-hidden pt-0"
                value="Time on Task Summary"
              >
                <ScrollArea
                  className="w-full h-full"
                  thumbClassName="bg-gradient-1"
                >
                  <ToteSummary programData={programData} />
                </ScrollArea>
              </TabsContent>
              <TabsContent
                value="Alignment Grid"
                className="h-full overflow-hidden pt-0"
              >
                <AlignmentGrid
                  programData={programData}
                  targetList={null}
                  forPrint={false}
                />
              </TabsContent>
              <TabsContent
                value="List to List"
                className="h-full overflow-hidden pt-0"
              >
                <ListToList programData={programData} />
              </TabsContent>
              <TabsContent
                value={`${programLabel} ${activitiesLabel}`}
                className="h-full overflow-hidden pt-0"
              >
                <ProgramActivities programData={programData} forPrint={false} />
              </TabsContent>
              <TabsContent
                value={`${courseLabel} ${activitiesLabel}`}
                className="h-full overflow-hidden pt-0"
              >
                <CourseActivities programData={programData} forPrint={false} />
              </TabsContent>
              <TabsContent
                value={`${courseLabel} ${modulesLabel}`}
                className="h-full overflow-hidden pt-0"
              >
                <CourseModules programData={programData} forPrint={false} />
              </TabsContent>
              {/* <TabsContent value="Printable Report">
                <PrintReport programData={programData} />
              </TabsContent> */}
            </div>
          </Tabs>
        </div>
      ) : (
        <div>No {programLabel} data</div>
      )}
    </div>
  );
});

export default Reports;
