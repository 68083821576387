import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";
import { AlertCircle } from "lucide-react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { z } from "zod";

import { API_PATHS, ApiUrls } from "~/api/ApiPaths";
import ForgotPassword from "~/components/login/ForgotPassword";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/ui/form";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "~/components/ui-rework/card";
import useAxios from "~/hooks/useAxios";
import { default as align } from "~/images/align-logo-updated.svg";
import loginImage from "~/images/login-background_light.svg";
import studio from "~/images/studio-1.svg";
import { useStore as useRootStore } from "~/models/Root";

import { Button } from "../ui-rework/button";
import { Input } from "../ui-rework/input";
import Link from "../ui-rework/link";

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  div.overflow-handle {
    display: flex;
    flex: 1;
    overflow: auto;
  }
`;

const SetDevLoginCredentials: React.FC = () => {
  const { setValue } = useFormContext();

  return (
    <div className="mt-4 flex flex-col items-center">
      <div>For demo/dev only, get credentials to login as:</div>
      <div className="flex gap-4 flex-wrap justify-center">
        <Button
          onClick={() => {
            setValue("email", "iDesignStudioAdmin@idesignedu.org");
            setValue("password", "idesignpassword");
          }}
          type="button"
        >
          iDesign Admin
        </Button>
        <Button
          onClick={() => {
            setValue("email", "iDesignDemoUser@idesignedu.org");
            setValue("password", "idesignpassword");
          }}
          type="button"
        >
          iDesign Demo
        </Button>
        <Button
          onClick={() => {
            setValue("email", "UCLADemoUser@idesignedu.org");
            setValue("password", "idesignpassword");
          }}
          type="button"
        >
          UCLA Demo
        </Button>
        <Button
          onClick={() => {
            setValue("email", "UMGCDemoUser@idesignedu.org");
            setValue("password", "idesignpassword");
          }}
          type="button"
        >
          UMGC Demo
        </Button>
      </div>
    </div>
  );
};

/**
 * @description Entry point component of the main application which uses a form requesting credentials for access, there will be SSO in the future.
 * for development purposes we have a SetDevLoginCredentials component to help with the tedious entering of credentials many times.
 */

const Login: React.FC = observer(() => {
  const {
    setAuthToken,
    setAppUser,
    setLoggedIn,
    getLoggedIn,
    resetAppUser,
    getEnvironment,
    getAuthToken,
    setGlobalFeatures,
  } = useRootStore();
  const [isLoggedIn] = useState(getLoggedIn());
  const navigate = useNavigate();
  const { data, error, payload, requestUrl, fetch, resetFetchedData, authToken,} =
    useAxios({
      method: "POST",
      initialValue: null,
    });
  const [loginError, setLoginError] = useState<string | undefined>();
  const doItOnce = useRef(false);
  const initialLoad = useRef(true);
  const environmentVariable = getEnvironment();
  const [showLogin, setShowLogin] = useState(true);
  const fetchGlobalFeatures = useRef(false);

  useEffect(() => {
    //If user navigates to login page and is logged in, we need to log them out.
    if (initialLoad.current && isLoggedIn) {
      setAuthToken("");
      resetAppUser();
      setLoggedIn(false);
      initialLoad.current = false;
    }
  }, [isLoggedIn, setAuthToken, resetAppUser, setLoggedIn]);

  const login = (email: string, password: string) => {
    //we need to call the API here, get the token if successful and return errors if not.
    doItOnce.current = true;
    payload.current = {
      email: email,
      password: password,
    };
    requestUrl.current = `${API_PATHS.LOGIN}`;
    fetch();
  };

  const formSchema = z.object({
    email: z
      .string()
      .email("Invalid email address")
      .max(100, "Invalid email address")
      .min(1, { message: "Email is required" }),
    password: z.string().min(1, { message: "Password is required" }),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setAuthToken("");
    login(values.email, values.password);
  }

  useEffect(() => {
    if (data && doItOnce.current) {
      doItOnce.current = false;
      if (data.issuccess) {
        // we will redirect and do extra stuff here.
        setAuthToken(data.entity.authToken);
        setAppUser({
          authtoken: data.entity.authToken,
          first_name: data.entity.first_name,
          last_name: data.entity.last_name,
          name: data.entity.name,
          user_type: data.entity.user_type,
          agency_id: data.entity.agency_id,
          agency_name: data.entity.agency_name.name,
          partner_id: data.entity.partner_id,
          partner_name: data.entity.partner.name,
          basic_permissions: data.entity.basic_permissions,
          user_permissions: data.entity.user_permissions,
          id: `${data.entity.id}`,
          email: data.entity.email,
          title: data.entity.title,
          created_at: data.entity.created_at,
          last_login: data.entity.last_login,
          password_expires_at: data.entity.password_expires_at,
          updated_at: data.entity.updated_at,
        });
        setLoggedIn(true);
        
        //fetch global features\
        fetchGlobalFeatures.current = true;
        resetFetchedData();
        requestUrl.current = `${ApiUrls.settings.getGlobalFeatures}`;
        authToken.current = getAuthToken();
        fetch("GET");

      } else {
        setLoginError(data.message);
      }
    }
    if (error) {
      setLoginError(error.message);
    }
  }, [
    data,
    error,
    navigate,
    setAuthToken,
    setAppUser,
    setLoggedIn,
    resetFetchedData,
    requestUrl,
    fetch,
    getAuthToken,
    authToken,
    payload,
  ]);

  useEffect(() => {
    if (
      data?.issuccess && data?.message === "Global feature flags returned"
    ) {
      fetchGlobalFeatures.current = false;
      console.log(data.entity);
      const features: string[] = [];
      data.entity.forEach((feature : any) => {
        if (feature.is_active)
        features.push(feature.name);
      });
      setGlobalFeatures(features);
      navigate("/dashboard", { replace: true });
    } else if (error) {
      console.log(error);
    }
  }, [data, error, navigate, setGlobalFeatures]);

  return (
    <AppWrapper className="light">
      <div
        className="fixed inset-0 bg-cover bg-center z-[-1]"
        style={{ backgroundImage: `url(${loginImage})` }}
      ></div>

      <div className="absolute flex flex-row w-full justify-between p-6">
        <img src={studio} alt="Studio Main Logo" />
        <img src={align} alt="Align Main Logo" />
      </div>
      <div className="grid h-full place-items-center p-0">
        {showLogin ? (
          <div>
            <Card className="w-[415px]">
              <CardHeader>
                <CardTitle className="m-0">Login</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex flex-col">
                  {loginError ? (
                    <Alert variant="error">
                      <AlertCircle className="h-4 w-4" />
                      <AlertTitle>Error</AlertTitle>
                      <AlertDescription>{loginError}</AlertDescription>
                    </Alert>
                  ) : null}
                  <FormProvider {...form}>
                    <>
                      <Form {...form}>
                        <form
                          onSubmit={form.handleSubmit((data) => onSubmit(data))}
                        >
                          <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                              <FormItem className="w-[345px] mb-6">
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <FormControl>
                                  <Input
                                    className="bg-transparent"
                                    placeholder="Enter Email"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                              <FormItem className="w-[345px] mb-4">
                                <FormLabel htmlFor="password">
                                  Password
                                </FormLabel>
                                <FormControl>
                                  <Input
                                    className="bg-transparent"
                                    placeholder="Enter Password"
                                    type="password"
                                    {...field}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <div className="w-full flex flex-row justify-end gap-4">
                            <Button
                              type="button"
                              variant="outline"
                              onClick={() => {
                                setShowLogin(false);
                              }}
                            >
                              Forgot Password
                            </Button>
                            <Button variant="primary" type="submit">
                              Login
                            </Button>
                          </div>
                        </form>
                      </Form>
                      {environmentVariable === "Local-Dev" ? (
                        <SetDevLoginCredentials />
                      ) : null}
                    </>
                  </FormProvider>
                </div>
              </CardContent>
            </Card>
          </div>
        ) : (
          <ForgotPassword setShowLogin={setShowLogin} />
        )}
      </div>
      <div className="absolute bottom-0 w-full text-center text-lg font-bold p-6 flex flex-row items-center justify-center">
        Powered by
        <Link size="m" inline to="https://www.idesignedu.org">
          iDesign
        </Link>
      </div>
    </AppWrapper>
  );
});

export default Login;
